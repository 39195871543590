<template>
    <div class="due-date">
        <div class="due-date__selector" :class="{ 'due-date__selector_disabled': componentDisabled }"
             @click="openModal">
            <div class="due-date__selector-img">
                <img src="@/assets/images/due_date.png" alt="Due date">
            </div>
            <div class="due-date__selector-label">
                <span v-if="!$slots.title" class="due-date__selector-title text-overflow-ellipsis">
                  {{ componentTitle }}
                </span>
                <span v-else class="due-date__selector-title text-overflow-ellipsis">
                    <slot name="title"></slot>
                </span>
                <span class="due-date__selector-summary">
                    <span v-if="!componentDisabled" class="text-overflow-ellipsis">
                        {{ modal ? 'Edit recurrence settings' : summary.recurrence }}
                    </span>
                    <span v-else class="text-overflow-ellipsis">
                        {{ !!Number(payload.enabled) ? summary.recurrence : 'Assign Date  —  Due Date' }}
                    </span>
                </span>
            </div>
        </div>

        <transition>
            <div v-if="modal" class="due-date__modal">
                <div class="due-date__modal-header">
                    <CIcon class="due-date__modal-close" name="cil-x" @click="closeModalAndSetDefaults"></CIcon>
                </div>
                <div class="due-date__modal-content">
                    <div class="due-date__modal-body">
                        <div class="due-date__title-wrapper">
                            <h1 class="due-date__title">Recurrence & Due Date</h1>
                            <p class="due-date__text">
                                Set recurrence to your templates, then add due dates to facilitate automation of your
                                tasks.
                            </p>
                        </div>
                        <div class="due-date__modal-form">
                            <div v-if="dueDates && !hideDates" :aria-disabled="datesDisabled">
                                <div class="due-date__modal-option">
                                    <div class="due-date__modal-option-counter">1</div>
                                    <span class="due-date__modal-option-name">Date Range</span>
                                </div>
                                <p class="due-date__modal-option-text">
                                    Set assign date and due date
                                </p>
                            </div>
                            <div v-if="dueDates && !hideDates" :aria-disabled="datesDisabled"
                                 class="due-date__modal-dates">
                                <DatePicker ref="picker" :disabled="datesDisabled" :model-value="dates.assign_date"
                                            style="width: 100%" :popover="{ positionFixed: true }"
                                            @update:modelValue="dates.assign_date = formatTime($event)">
                                    <template #default="{ inputValue, togglePopover }">
                                        <div class="due-date__modal-dates-field" @click="togglePopover">
                                            <CIcon name="cil-calendar"></CIcon>
                                            {{ inputValue ? formatDate(dates.assign_date) : 'Assign Date' }}
                                        </div>
                                    </template>
                                </DatePicker>

                                <DatePicker ref="picker" :disabled="datesDisabled" :model-value="dates.due_date"
                                            style="width: 100%" :popover="{ positionFixed: true }"
                                            @update:modelValue="dates.due_date = formatTime($event)">
                                    <template #default="{ inputValue, togglePopover }">
                                        <div class="due-date__modal-dates-field" @click="togglePopover">
                                            <CIcon name="cil-calendar"></CIcon>
                                            {{ inputValue ? formatDate(dates.due_date) : 'Due date' }}
                                        </div>
                                    </template>
                                </DatePicker>
                            </div>
                            <hr v-if="dueDates && !hideDates" class="due-date__modal-divider"
                                :aria-disabled="recurrenceDisabled"/>
                            <div>
                                <div class="due-date__modal-option">
                                    <div class="due-date__modal-option-counter">
                                        {{ dueDates && !hideDates ? 2 : 1 }}
                                    </div>
                                    <span class="due-date__modal-option-name">Select Recurrence</span>
                                </div>
                                <p class="due-date__modal-option-text">
                                    Set how often a task needs to be repeated after a project has started.
                                </p>
                            </div>
                            <Multiselect ref="enabledSelect" :aria-disabled="recurrenceDisabled"
                                         :disabled="recurrenceDisabled" label="label"
                                         class="base-dropdown base-dropdown_type" v-model="payload.enabled"
                                         :can-clear="false" :can-deselect="false" :options="options.recurrence_enabled">
                                <template #option="{ option }">
                                    <div style="width: 100%;padding: 12px;"
                                         @click="onRecurrenceTypeSelect(option.value)">
                                        {{ vocabulary.types[option.value] }}
                                    </div>
                                </template>
                                <template #singlelabel>
                                    <div class="w-100" style="padding-left: 16px;">
                                        {{ vocabulary.types[payload.enabled] }}
                                    </div>
                                </template>
                                <template #caret>
                                    <svg style="color: #0895D1; width: 23px;margin-right: 10px;" viewBox="0 0 24 24"
                                         fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                              d="M7.41 8.83984L12 13.4198L16.59 8.83984L18 10.2498L12 16.2498L6 10.2498L7.41 8.83984Z"
                                              fill="currentColor"/>
                                    </svg>
                                </template>
                            </Multiselect>

                            <span v-if="Boolean(Number(payload.enabled))"
                                  style="font-size: 14px;color: #677A89;">{{ summary.recurrence }}</span>

                            <hr v-if="!dueDates" class="due-date__modal-divider"/>

                            <div v-if="!dueDates && !is_indefinite" class="due-date__modal-days">
                                <div class="due-date__modal-days-config">
                                    <div class="due-date__modal-option">
                                        <div class="due-date__modal-option-counter">2</div>
                                        <span class="due-date__modal-option-name">Select task due date</span>
                                    </div>
                                    <p class="due-date__modal-option-text">
                                        Set the amount of days you allow before this task is overdue after you have
                                        set the recurrence.
                                    </p>
                                </div>

                                <div class="due-date__modal-days-config">
                                    <span class="due-date__text">Overdue after (in day)</span>
                                    <div class="due-date__modal-days-handler">
                                        <button @click="changeOverdueDays('-')">-</button>
                                        <input type="text" v-mask="['###']" v-model.number="payload.due_date_days"/>
                                        <button @click="changeOverdueDays('+')">+</button>
                                    </div>
                                    <div class="due-date__modal-days-options">
                                        <button @click="changeOverdueDays(3)">+3 days</button>
                                        <button @click="changeOverdueDays(5)">+5 days</button>
                                        <button @click="changeOverdueDays(10)">+10 days</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="due-date__modal-buttons">
                            <CButton class="due-date__modal-save" color="primary" :disabled="changesDid"
                                     @click="onSave">
                                Save
                            </CButton>
                        </div>
                    </div>
                </div>
            </div>
        </transition>

        <div class="overflow-modal">
            <CModal :visible="modal_inner" backdrop="static">
                <div class="due-date__inner-modal">
                    <div style="display: flex;justify-content: flex-end;">
                        <CIcon class="due-date__modal-close" name="cil-x" @click="resetSettings"></CIcon>
                    </div>
                    <div class="due-date__title-wrapper">
                        <h1 class="due-date__title">Custom Recurrence</h1>
                    </div>
                    <div class="due-date__inner-modal-form">

                        <div class="due-date__inner-modal-field">
                            <span class="due-date__inner-modal-title">Repeat every</span>
                            <Multiselect class="base-dropdown base-dropdown_day" :value="payload.every"
                                         :can-clear="false" :can-deselect="false" :options="options.recurrence_every"
                                         @change="payload.every = $event">
                                <template #caret>
                                    <svg style="color: #0895D1; width: 23px;margin-right: 10px;" viewBox="0 0 24 24"
                                         fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                              d="M7.41 8.83984L12 13.4198L16.59 8.83984L18 10.2498L12 16.2498L6 10.2498L7.41 8.83984Z"
                                              fill="currentColor"/>
                                    </svg>
                                </template>
                            </Multiselect>
                            <Multiselect class="base-dropdown base-dropdown_week" :value="payload.period"
                                         :can-deselect="false" :can-clear="false" :options="options.recurrence_period"
                                         @change="payload.period = $event">
                                <template #caret>
                                    <svg style="color: #0895D1; width: 23px;margin-right: 10px;" viewBox="0 0 24 24"
                                         fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                              d="M7.41 8.83984L12 13.4198L16.59 8.83984L18 10.2498L12 16.2498L6 10.2498L7.41 8.83984Z"
                                              fill="currentColor"/>
                                    </svg>
                                </template>
                            </Multiselect>
                        </div>

                        <div v-if="payload.period === 365">
                            <span style="font-size: 12px;color: #677A89;">From today’s date if the project is not completed</span>
                        </div>

                        <hr v-else class="due-date__inner-modal-divider"/>

                        <div v-if="payload.period === 7" class="due-date__inner-modal-field">
                            <span class="due-date__inner-modal-title">Repeat on</span>
                            <div v-for="day in options.recurrence_days_of_week" :key="'week_day' + day"
                                 class="due-date__inner-modal-day"
                                 :class="{ 'due-date__inner-modal-day_selected': payload.days_of_week.includes(day) }"
                                 @click.stop="onWeekDaySelect(day)">
                                {{ daysVocabulary[day] }}
                            </div>
                        </div>

                        <div v-if="payload.period === 30" class="due-date__inner-modal-field">
                            <span class="due-date__inner-modal-title">Repeat on day</span>
                            <Multiselect class="base-dropdown base-dropdown_day"
                                         :value="payload.day_of_month" :can-deselect="false"
                                         :can-clear="false" :options="options.recurrence_day_of_month"
                                         @change="payload.day_of_month = $event">
                                <template #caret>
                                    <svg style="color: #0895D1; width: 23px;margin-right: 10px;" viewBox="0 0 24 24"
                                         fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                              d="M7.41 8.83984L12 13.4198L16.59 8.83984L18 10.2498L12 16.2498L6 10.2498L7.41 8.83984Z"
                                              fill="currentColor"/>
                                    </svg>
                                </template>
                            </Multiselect>
                        </div>

                        <hr class="due-date__inner-modal-divider"/>

                        <span class="due-date__inner-modal-title">Recurrence ends</span>
                        <div class="due-date__inner-modal-option due-date__inner-modal-option_top">
                            <CFormCheck :checked="payload.type === 'project_ends'" type="radio"
                                        name="plan_id" label="At project completion"
                                        @click="payload.type = 'project_ends'"/>
                        </div>
                        <div class="due-date__inner-modal-option">
                            <CFormCheck :checked="payload.type === 'repeats'" type="radio"
                                        name="plan_id" label="On" @change="payload.type = 'repeats'"/>
                            <Multiselect :aria-disabled="payload.type !== 'repeats'"
                                         class="base-dropdown base-dropdown_day"
                                         :value="payload.until_repeats" :can-deselect="false"
                                         :can-clear="false" :options="options.recurrence_until_repeats"
                                         @change="payload.until_repeats = $event">
                                <template #caret>
                                    <svg style="color: #0895D1; width: 23px;margin-right: 10px;" viewBox="0 0 24 24"
                                         fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                              d="M7.41 8.83984L12 13.4198L16.59 8.83984L18 10.2498L12 16.2498L6 10.2498L7.41 8.83984Z"
                                              fill="currentColor"/>
                                    </svg>
                                </template>
                            </Multiselect>
                            <span :aria-disabled="payload.type !== 'repeats'">occurrences</span>
                        </div>

                        <div class="due-date__inner-modal-option">
                            <CFormCheck :checked="payload.type === 'date'" type="radio" name="plan_id"
                                        label="After" @change="payload.type = 'date'"/>
                            <DatePicker :aria-disabled="payload.type !== 'date'" ref="picker"
                                        :model-value="payload.until_date" style="width: 100%"
                                        :popover="{ positionFixed: true }"
                                        @update:modelValue="payload.until_date = formatTime($event)">
                                <template #default="{ inputValue, togglePopover }">
                                    <div class="due-date__inner-modal-picker"
                                         :style="{ color: !!payload.recurrence_until_date ? '#1C262F' : ''}"
                                         @click="togglePopover">
                                        <CIcon :style="{ color: !!payload.recurrence_until_date ? '#1C262F' : ''}"
                                               name="cil-calendar"></CIcon>
                                        {{ inputValue ? formatDate(payload.until_date) : 'mm/dd/yyyy' }}
                                    </div>
                                </template>
                            </DatePicker>
                        </div>

                        <hr class="due-date__inner-modal-divider"/>
                        <div class="due-date__modal-buttons">
                            <CButton class="due-date__modal-save" color="primary" variant="outline"
                                     @click="resetSettings">
                                Cancel
                            </CButton>
                            <CButton class="due-date__modal-save" color="primary" :disabled="!settingsValid"
                                     @click="closeInnerModal">
                                Save
                            </CButton>
                        </div>

                    </div>
                </div>
            </CModal>
        </div>
    </div>
</template>

<script>
import {defineComponent} from 'vue'
import OptionsDropdown from "@/components/CreateTask/OptionsDropdown.vue";
import Multiselect from '@vueform/multiselect';
import {DatePicker} from 'v-calendar'
import moment from "moment/moment";
import {mask} from 'vue-the-mask'

export default defineComponent({
    name: "DueDateSelector",
    components: {OptionsDropdown, Multiselect, DatePicker},
    directives: {mask},
    props: {
        recurrence: Object,
        due_date_days: {
            type: Number,
            default: 0
        },
        assign_date: String,
        due_date: String,
        hideDates: Boolean,
        permissions: Array,
        is_indefinite: Boolean,
    },
    data() {
        return {
            modal: false,
            modal_inner: false,

            options: {
                recurrence_enabled: ['0', '1'],
                recurrence_every: [...Array(99).keys()].map(i => i + 1),
                recurrence_period: [
                    {value: 7, label: 'week'},
                    {value: 30, label: 'month'},
                    {value: 365, label: 'year'}
                ],
                recurrence_days_of_week: ['7', '1', '2', '3', '4', '5', '6'],
                recurrence_until_repeats: [...Array(99).keys()].map(i => i + 1),
                recurrence_day_of_month: [...Array(31).keys()].map(i => i + 1)
            },

            payload: {
                due_date_days: 0,
                enabled: '0',
                every: 1,
                period: 7,
                days_of_week: ['1'],
                until_repeats: 1,
                day_of_month: 1,
                type: 'project_ends',
                until_date: '',
            },

            dates: {
                assign_date: null,
                due_date: null
            },

            payloadSnapshot: null
        }
    },
    computed: {
        summary() {
            let repeat_summary = '';
            let end_summary = '';

            function checkMultiplicity(num) {
                return num > 1
            }

            function ordinal_suffix_of(i) {
                var j = i % 10,
                    k = i % 100;
                if (j == 1 && k != 11) {
                    return i + "st";
                }
                if (j == 2 && k != 12) {
                    return i + "nd";
                }
                if (j == 3 && k != 13) {
                    return i + "rd";
                }
                return i + "th";
            }

            function formatDate(date) {
                return moment(date).format('MM/DD/YYYY')
            }

            if (this.payload.enabled) {
                if (this.payload.period === 7) {
                    const daysMultiplicity = checkMultiplicity(this.payload.every);
                    const daysNames = this.payload.days_of_week.sort().map(day => ' ' + this.daysNames[day])

                    function moveSundayToFront(arr) {
                        const index = arr.indexOf(" Sunday");
                        if (index !== -1) {
                            arr.splice(index, 1);
                            arr.unshift("Sunday");
                        }
                    }

                    moveSundayToFront(daysNames)

                    if (daysMultiplicity) {
                        repeat_summary = `${this.payload.every} weeks on ${daysNames}`
                    } else {
                        repeat_summary = `week on ${daysNames}`
                    }
                }

                if (this.payload.period === 30) {
                    const daysMultiplicity = checkMultiplicity(this.payload.every);
                    const daysNames = this.payload.days_of_week.sort().map(day => ' ' + this.daysNames[day])

                    if (daysMultiplicity) {
                        repeat_summary = `${this.payload.every} months on ${ordinal_suffix_of(this.payload.day_of_month)}`
                    } else {
                        repeat_summary = `month on ${ordinal_suffix_of(this.payload.day_of_month)}`
                    }
                }

                if (this.payload.period === 365) {
                    const daysMultiplicity = checkMultiplicity(this.payload.every);
                    const daysNames = this.payload.days_of_week.sort().map(day => ' ' + this.daysNames[day])

                    if (daysMultiplicity) {
                        repeat_summary = `${this.payload.every} years from today's date`
                    } else {
                        repeat_summary = `year from today's date`
                    }
                }

                switch (this.payload.type) {
                    case 'project_ends':
                        end_summary = 'until project completion'
                        break;
                    case 'repeats':
                        end_summary = `on ${this.payload.until_repeats} occurrences`
                        break;
                    case 'date':
                        end_summary = `until ${moment(this.payload.until_date).format('MM/DD/YYYY')}`
                        break;
                }
            }

            return {
                recurrence: !!Number(this.payload.enabled) ? `Every ${repeat_summary}; ${end_summary}` : 'Do not repeat',
                due_date_days: `Task Due Date- ${this.payload.due_date_days} day(s) after recurrence is set`,
                date_range: this.dueDates ? formatDate(this.assign_date) + ' — ' + formatDate(this.due_date) : ''
            }
        },
        daysVocabulary() {
            return {
                7: 'su',
                1: 'm',
                2: 't',
                3: 'w',
                4: 'th',
                5: 'f',
                6: 'sa'
            }
        },
        daysNames() {
            return {
                7: 'Sunday',
                1: 'Monday',
                2: 'Tuesday',
                3: 'Wednesday',
                4: 'Thursday',
                5: 'Friday',
                6: 'Saturday'
            }
        },
        vocabulary() {
            return {
                types: {
                    '0': 'Do not repeat',
                    '1': 'Custom recurrence'
                }
            }
        },
        dueDates() {
            return this.due_date && this.assign_date
        },
        changesDid() {
            function deepEqual(object1, object2) {
                const keys1 = Object.keys(object1);
                const keys2 = Object.keys(object2);

                if (keys1.length !== keys2.length) {
                    return false;
                }

                for (const key of keys1) {
                    const val1 = object1[key];
                    const val2 = object2[key];
                    const areObjects = isObject(val1) && isObject(val2);
                    if (
                        areObjects && !deepEqual(val1, val2) ||
                        !areObjects && val1 !== val2
                    ) {
                        return false;
                    }
                }

                return true;
            }

            function isObject(object) {
                return object != null && typeof object === 'object';
            }

            return deepEqual(this.payload, this.payloadSnapshot) && deepEqual(this.dates, {
                assign_date: this.assign_date,
                due_date: this.due_date
            })
        },
        settingsValid() {
            if (this.payload.period === 7 && !this.payload.days_of_week.length) return false
            if (this.payload.period === 30 && !this.payload.day_of_month) return false
            if (this.payload.type === 'date' && !this.payload.until_date) return false

            return true
        },
        componentDisabled() {
            return !!this.permissions === false
        },
        recurrenceDisabled() {
            return !this.permissions.includes('recurrence') || this.permissions.includes(false)
        },
        datesDisabled() {
            return (!this.permissions.includes('assign_date') && !this.permissions.includes('due_date'))
        },
        componentTitle() {
            if (this.is_indefinite) {
                return 'N/A - N/A'
            }

            if (this.dueDates) {
                return this.summary.date_range
            }

            return this.summary.due_date_days
        }
    },
    watch: {
        recurrence(val) {
            if (val) this.setDefaultValues();
        },
        modal(val) {
            this.$emit('modalDisplayChange', val)
        }
    },
    created() {
        this.setDefaultValues();
        this.logInfo()
    },
    methods: {
        openModal() {
            if (this.componentDisabled) return false

            this.modal = true;
            this.payloadSnapshot = Object.assign({}, this.payload);

            if (this.assign_date && this.due_date) {
                this.dates.assign_date = this.assign_date;
                this.dates.due_date = this.due_date;
            }
        },
        closeModal() {
            this.modal = false;
            this.payloadSnapshot = null;
            Object.keys(this.dates).forEach(key => this.dates[key] = null)
        },
        closeModalAndSetDefaults() {
            this.setDefaultValues();
            this.closeModal();
        },
        closeInnerModal() {
            this.modal_inner = false
        },
        resetSettings() {
            this.setDefaultValues();
            this.closeInnerModal();
        },
        setDefaultValues() {
            if (this.payloadSnapshot) {
                this.payload = Object.assign({}, this.payloadSnapshot)
                return
            }

            if (this.assign_date && this.due_date) {
                this.dates.assign_date = this.assign_date;
                this.dates.due_date = this.due_date;
            }

            if (!this.recurrence) return false

            Object
                .keys(this.payload)
                .forEach(key => this.payload[key] = this.recurrence[key]);

            this.payload.due_date_days = this.due_date_days;

            if (typeof this.payload.enabled === 'boolean') {
                this.payload.enabled = this.payload.enabled ? 1 : 0
            }
        },
        onSave() {
            const data = Object.assign({}, this.payload, this.dueDates || this.is_indefinite ? this.dates : {});
            this.$emit('saved', data);
            this.closeModal();
        },
        changeOverdueDays(payload) {
            switch (payload) {
                case '+':
                    if (this.payload.due_date_days < 999) this.payload.due_date_days++;
                    break;
                case '-':
                    if (this.payload.due_date_days) this.payload.due_date_days--;
                    break;
                default:
                    this.payload.due_date_days = payload
            }
        },
        onWeekDaySelect(value) {
            const daySelected = this.payload.days_of_week.includes(value);

            if (daySelected) {
                this.payload.days_of_week = this.payload.days_of_week.filter(v => value !== v)
            } else {
                this.payload.days_of_week = [...this.payload.days_of_week, value]
            }
        },
        formatTime(time) {
            return moment(time).format('YYYY-MM-DD')
        },
        formatDate(date) {
            return moment(date).format('MM/DD/YYYY')
        },
        onRecurrenceTypeSelect(type) {
            this.$refs.enabledSelect.close()
            this.modal_inner = !!Number(type);
        },
        logInfo() {
        }
    }
})
</script>

<style lang="scss">
$active: #0068AD;

.base-dropdown {
    margin: 0;

    --ms-option-bg-selected: #F2F5F8;
    --ms-option-bg-selected-pointed: #F2F5F8;
    --ms-option-color-selected: #1C262F;
    --ms-option-color-selected-pointed: #1C262F;

    --ms-option-bg-pointed: rgba(242, 245, 248, 0.65);

    --ms-radius: 8px;
    --ms-border-width: 1px;
    --ms-border-color: #9FAFBC;

    &_day {
        width: 80px;

        & .multiselect-single-label {
            padding-right: 0 !important;
        }
    }

    &_week {
        width: 160px;
    }

    &_type {

        & .multiselect-option {
            padding: 0;
        }
    }

    & .multiselect-dropdown {
        overflow-y: auto;
    }
}

.due-date {

    & input::-webkit-outer-spin-button,
    & input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    & input[type=number] {
        -moz-appearance: textfield;
    }

    &__title {
        font-size: 24px !important;
        font-weight: 600;
        text-align: center;
        color: #1C262F;
        margin-bottom: 12px;

        &-wrapper {
            position: relative;

            &::after {
                position: absolute;
                left: 0;
                right: 0;
                margin: 0 auto;
                content: '';
                width: 120px;
                height: 2px;
                background: #0081C2;
            }
        }
    }

    &__text {
        display: block;
        font-size: 16px !important;
        color: #677A89;
        text-align: center;
        max-width: 380px;
        margin: 0 auto 12px auto;
    }

    &__modal {
        position: fixed;
        top: 100px;
        left: 280px;
        z-index: 1030;

        width: 560px;

        background: white;
        box-shadow: 0 4px 12px 2px #00000040;
        border-radius: 12px;

        @media (max-height: 600px) {
            top: 100px;
            bottom: 100px;
            height: auto;
        }

        &-content {
            height: calc(100% - 36px);
            overflow-y: auto;
        }

        &-dates {
            display: flex;
            gap: 16px;

            &-field {
                padding-bottom: 8px;
                border-bottom: 1px solid #BAC6CF;
                font-size: 16px !important;
                color: #677A89;
            }
        }

        &-header {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            background: #F2F5F8;
            border-radius: 12px 12px 0 0;
            height: 36px;
            padding: 0 12px;
        }

        &-close {
            cursor: pointer;
            color: #9FAFBC;

            &:hover {
                color: black;
            }
        }

        &-body {
            display: flex;
            flex-direction: column;
            gap: 48px;

            padding: 32px 40px 40px 40px;
            height: auto;
            width: 100%;
        }

        &-form {
            display: flex;
            flex-direction: column;
            gap: 16px;
        }

        &-buttons {
            display: flex;
            justify-content: center;
            gap: 16px;
        }

        &-save {
            width: 200px;
        }

        &-option {
            display: flex;
            align-items: center;
            gap: 8px;

            &-counter {
                display: flex;
                align-items: center;
                justify-content: center;

                height: 16px;
                width: 16px;
                border-radius: 200px;
                background: #0068AD;
                font-size: 10px !important;
                color: #fff;
            }

            &-name {
                font-family: "Segoe UI", sans-serif;
                font-size: 16px;
                font-weight: 400;
                color: #1C262F;
            }

            &-text {
                font-size: 14px;
                color: #677A89;
                padding-left: 24px;
                margin: 0;
            }
        }

        &-divider {
            border: none;
            height: 1px;
            background: #BAC6CF;
            width: 100%;
            margin: 12px 0 12px 0;
        }

        &-days {
            display: flex;

            &-config {
                flex: 1;
            }

            &-handler {
                display: flex;
                justify-content: center;

                & input {
                    width: 80px;
                    height: 28px;
                    border: 1px solid #1C262F;
                    border-radius: 4px;
                    font-size: 14px;
                    text-align: center;

                    &:focus {
                        border: 1px solid $active;
                        outline: none;
                    }
                }

                & button {
                    height: 28px;
                    display: flex;
                    align-items: center;
                    background: none;
                    border: none;
                    font-size: 30px;
                    transform: translateY(-3px);

                    &:hover {
                        color: #0068AD;
                    }
                }
            }

            &-options {
                display: flex;
                justify-content: center;
                gap: 8px;
                margin-top: 24px;

                & button {
                    background: none;
                    border: 1px solid #BAC6CF;
                    border-radius: 32px;
                    color: #677A89;
                    font-size: 12px;

                    &:hover {
                        background: #BAC6CF;
                        color: #fff;
                        border: 1px solid transparent;
                    }
                }
            }
        }
    }

    &__selector {
        padding: 8px 16px;
        background: #F2F5F8;
        border-radius: 8px;
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;

        &_disabled {
            cursor: default;
        }

        &:hover {
            background: #ecf5fd;
        }

        &-img {
            height: 24px;
            width: 24px;
        }

        &-label {
            display: flex;
            flex-direction: column;

            width: calc(100% - 24px);
        }

        &-title {
            font-size: 16px;
            color: #1C262F;
            font-weight: 400;
        }

        &-summary {
            font-size: 14px;
            color: #677A89;
        }
    }

    &__inner-modal {
        padding: 40px;
        width: 560px;

        &-title {
            color: #1C262F;
            font-weight: 600;
            font-size: 16px;
        }

        &-form {
            padding: 40px 0 0 0;
        }

        &-field {
            display: flex;
            align-items: center;
            gap: 16px;
            margin-bottom: 32px;
        }

        &-divider {
            border: none;
            height: 1px;
            background: #BAC6CF;
            margin-bottom: 24px;
        }

        &-day {
            background: #C3CDD5;
            height: 32px;
            width: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            border-radius: 200px;
            font-weight: 600;
            font-size: 14px;
            text-transform: capitalize;
            cursor: pointer;

            &:hover {
                background: #aeb5bb;
            }

            &_selected {
                background: #0895D1;

                &:hover {
                    background: #0895D1;
                }
            }
        }

        &-picker {
            width: 170px;
            border-bottom: 1px solid #C3CDD5;
            color: #BAC6CF;

            & svg {
                color: #BAC6CF;
            }
        }

        &-option {
            display: flex;
            align-items: center;
            gap: 16px;
            margin-bottom: 28px;

            &_top {
                margin-top: 24px;
            }
        }
    }
}

.v-enter-active,
.v-leave-active {
    transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>
