<template>
    <div class="cn-form-input__wrapper w-100">
        <div
            :class="[ 'cn-form-input', 'cn-form-input-' + variant, variant !== 'ghost' ? 'form-floating' : '', isFocused ? 'cn-form-input-border_focused' : '', invalid ? 'cn-form-input_invalid-' + variant : '' ]"
            @click.prevent="handleFocus">
            <input v-if="mask === 'money'" ref="input" class="cn-form-input__input form-control" data-gramm="false"
                   v-money="maskMoney" :value="modelValue" required :disabled="disabled"
                   :class="{ hasValue: modelValue }" :placeholder="placeholderText"
                   @input="$emit('update:modelValue', $event.target.value)" @blur="$emit('blur', $event.target.value)"/>

            <input v-else-if="!!mask" class="cn-form-input__input form-control" data-gramm="false" ref="input"
                   v-mask="mask" :value="modelValue" :type="type" required :disabled="disabled" :maxlength="maxLength"
                   :placeholder="placeholderText" @keypress="onKeyPress" @input="onInput" @blur="onBlur"/>

            <input v-else class="cn-form-input__input form-control" data-gramm="false" ref="input" :name="name"
                   :value="modelValue" :type="type" :disabled="disabled" :placeholder="placeholderText"
                   :maxlength="maxLength" required @keypress="onKeyPress" @input="onInput" @blur="onBlur"/>

            <label v-show="!!label">
                {{ label }}
                <sup v-if="required && !disabled" :class="{ 'text-error': true }">*</sup>
            </label>

            <div v-if="$slots.icon" class="cn-form-input__icon">
                <slot name="icon"></slot>
            </div>

        </div>

        <CFormText v-if="error" class="text-error">{{ error }}</CFormText>
    </div>


</template>

<script>
import {mask} from 'vue-the-mask'

export default {
    name: 'CNInput',
    directives: {mask},
    props: {
        numbersOnly: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            default: ''
        },
        invalid: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: 'text',
        },
        label: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
        // eslint-disable-next-line vue/require-default-prop
        modelValue: String,
        variant: {
            type: String,
            default: 'border',
        },
        error: {
            type: String,
            default: '',
        },
        required: {
            type: Boolean,
            default: false,
        },
        mask: {
            type: String,
            default: '',
        },
        maskMoney: {
            type: [Object, String],
            default: '',
        },
        maxLength: {
            type: Number,
            default: undefined,
        },
    },
    emits: ['update:modelValue', 'blur'],
    data() {
        return {
            isFocused: false
        }
    },
    methods: {
        onKeyPress($event) {
            if (this.numbersOnly) {
                let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
                if ((keyCode < 48 || keyCode > 57)) { // 46 is dot
                    $event.preventDefault();
                }
            }
        },
        onInput(event) {
            this.$emit('update:modelValue', event.target.value)
        },
        onBlur(e) {
            this.$emit('blur', e.target.value)
            this.isFocused = false
        },
        handleFocus() {
            this.isFocused = true
            this.$emit('focus');
            this.$nextTick(() => this.$refs.input.focus())
        },
    },
    computed: {
        placeholderText() {
            if (this.variant === 'ghost') return ''

            return this.placeholder ? this.placeholder : this.label
        }
    }
}
</script>
<style lang="scss">

// COMMON WRAPPER STYLES && Invalid Styles

.form-control[readonly] {
    background: none !important;
}

.form-control:focus {
    box-shadow: 0 0 0 4px #ABE7FA;
    border-color: #86b7fe;
}

.cn-form-input {
    width: 100%;
    position: relative;
    background: white;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type='number'] {
        -moz-appearance: textfield;
    }

    &__icon {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto 0;
        right: 0;

        display: flex;
        align-items: center;
        padding-right: 16px;
    }

    input {
        width: 100%;
        outline: none;
        border: none;
        background: white;
    }

    input:focus {
        outline: none;
    }

    &_invalid-border {

        & .cn-form-input__input {
            border-color: #dc3545 !important;
            box-shadow: 0 0 0 .25rem rgba(220, 53, 69, .25) !important;
        }
    }

    &_invalid-ghost {
        border-color: #dc3545 !important;
    }
}

.form-floating > label {
    color: #677a89;
}

// "Border" input type

.cn-form-input-border {

    input {
        height: 56px;
        width: 100%;

        border: 1px solid #9FAFBC;
        border-radius: 8px;
        font-size: 16px;
        padding: 16px 16px 16px 16px;
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

        &:focus {
            border-color: #86b7fe;
        }
    }

    input::placeholder {
        color: #677a89;
    }
}

.cn-form-input-ghost {
    border-radius: 0;
    border-bottom: 1px solid #c3cdd5;

    & .form-control:focus {
        box-shadow: none;
        border-color: unset;
    }

    label {
        cursor: pointer;
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.2px;
        color: #9aa8b5;
        -webkit-transition: 0.2s;
        transition: 0.2s;
    }

    input {
        padding: 0;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.2px;

        color: #1c262f;
        caret-color: #1c262f;
    }

    input:disabled.hasValue + label,
    input:valid + label,
    input:focus + label {
        display: none;
    }

    input::placeholder {
        color: #677a89;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
    }
}

</style>
