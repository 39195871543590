import BaseMethods from '../BaseMethods';
import {getData} from "@/api/v2/utils/DataHandler";

export default new (class extends BaseMethods {
    endpointRoute = '/api/v2/templates';

    async download(id) {
        return this.asDownload(`${this.endpointRoute}/${id}/download`);
    }

    async upload(id, data) {
        const route = `${this.endpointRoute}/${id}/upload`;

        return this.asFormData().post(route, data).then(getData);
    }

    async destroyMany(params) {
        const route = `${this.endpointRoute}/destroy-many`;

        return this.asJson().delete(route, {params}).then(getData);
    }
})();
