<template>
    <div class="c-action">
        <NewScopeModal :visible="addScopePopup" :projects="projects" :loading-options="loadingProjectsList"
                       :disabled-button="disabledCreateScopeButton"
                       :active-project="(!ignoreActiveProjectId && $route.params?.id) || null"
                       :error="permissionError" @close="closeScopePopup" @fetchOptions="fetchActiveProjectsBySearch"
                       @selectedProjectId="selectedProjectId"/>

        <CDropdown ref="CActionItem" class="c-action__item">
            <CDropdownToggle :key="actions" class="c-action__toggle" color="primary" variant="outline"
                             :disabled="!actions.length">
                Action
                <CIcon name="cilArrowDown"/>
            </CDropdownToggle>

            <CDropdownMenu>
                <CDropdownItem v-for="action in actions" :key="action.label" @click="action.event()">
                    {{ action.label }}
                </CDropdownItem>
            </CDropdownMenu>
        </CDropdown>

        <CDropdown class="c-action__item" v-if="isGeneralPlan">
            <CDropdownToggle class="c-action__toggle" color="primary">
                <CIcon name="cilPlus"/>
                Add
            </CDropdownToggle>

            <CDropdownMenu>
                <CDropdownItem v-for="addAction in addActions" :key="addAction.label" @click="addAction.event()">
                    <CIcon name="cilPlus"/>
                    {{ addAction.label }}
                </CDropdownItem>
            </CDropdownMenu>
        </CDropdown>
    </div>
</template>

<script>
import NewScopeModal from '@/components/Modals/NewScopeModal'
import Gate from '@/services/Gate/Gate'
import {notifyByPlan, permissionError, updatePlan} from '@/services/Notify/Toasts'
import {mapGetters} from "vuex";

export default {
    name: 'CAction',
    components: {NewScopeModal},
    inject: ['toast'],
    props: {
        actions: {
            type: Array,
            default: () => [],
        },
        ignoreActiveProjectId: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            addScopePopup: false,
            projects: [],
            loadingProjectsList: false,
            disabledCreateScopeButton: true,
            permissionError: '',

            addActions: [
                {
                    label: 'New Project',
                    event: () => this.addProject(),
                },
                {
                    label: 'New Scope',
                    event: () => this.addScope(),
                },
            ],
            permissionNotify: permissionError,
            planNotify: updatePlan,
        }
    },
    computed: {
        ...mapGetters([
            'isGeneralPlan'
        ])
    },
    async mounted() {
        await this.fetchActiveProjectsBySearch()
        this.$EventBus.on('openNewScopePopup', this.openScopePopup)
    },
    unmounted() {
        this.$EventBus.off('openNewScopePopup', this.openScopePopup)
    },
    methods: {
        async checkPermission() {
            const check = await Gate.can('create', 'project')
            if (!check) notifyByPlan()

            return check
        },
        async addProject() {
            if (await this.checkPermission()) {
                this.$router.push({name: 'ProjectNew'})
            }
        },
        async addScope() {
            if (await this.checkPermission()) {
                this.openScopePopup()
            }
        },
        openScopePopup() {
            this.addScopePopup = true
        },
        closeScopePopup() {
            this.addScopePopup = false
            this.projects = []
            this.disabledCreateScopeButton = true
            this.permissionError = ''

            this.fetchActiveProjectsBySearch()
        },
        fetchActiveProjectsBySearch(value) {
            this.loadingProjectsList = true
            this.$http.projects
                .fetchProjects({
                    params: {
                        per_page: -1,
                        page: 1,
                        search: value,
                        status: ['active'],
                        sort_field: '',
                        sort_direction: 'desc',
                    },
                })
                .then(({data: {data}}) => {
                    this.projects = data.map((item) => ({
                        value: item.id,
                        label: item.name,
                        info: item,
                    }))
                })
                .finally(() => {
                    this.loadingProjectsList = false
                })
        },
        selectedProjectId(id) {
            this.permissionError = ''
            if (!id) return

            this.$http.scope
                .scopeCheckPermission(id)
                .then(({data}) => {
                    this.disabledCreateScopeButton = !data.status
                })
                .catch((error) => {
                    this.disabledCreateScopeButton = true
                    this.permissionError = `${error.response.data.message} Please choose another project`
                })
        }
    },
}
</script>

<style lang="scss">
.c-action {
    display: flex;

    &__item {
        &:not(:last-child) {
            margin-right: 16px;
        }
    }

    &__toggle {
        &::after {
            display: none;
        }
    }

    .dropdown {
        &-menu {
            transform: translate(0px, 45px) !important;
        }

        &-item {
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 8px 18px;
            color: #677A89 !important;
            font-size: 14px;

            &:hover {
                color: #4f5d69 !important;
            }
        }
    }

}
</style>
