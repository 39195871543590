<template>
    <div>
        <div :class="['cn-form-input-date', 'cn-form-input-date-'+variant, invalid ? 'validation-error' : '']"
             @click.prevent="handleFocus">
            <DatePicker ref="picker" :model-value="modelValue" :available-dates="{ start: start, end: end }"
                        :mode="mode" style="width: 100%"
                        :masks="{ input: `${format.date}${ mode === 'dateTime' ? ' ' + format.time : '' }`, inputDateTime: `${format.date} ${format.time}`, }"
                        :popover="{ positionFixed: true }" @update:modelValue="selected">
                <template #default="{ inputValue, togglePopover }">
                    <input ref="input" data-gramm="false" required :disabled="disabled" :value="inputValue"
                           :placeholder="placeholder" :readonly="true" :class="{ 'cn-valid': !!inputValue }"
                           @input="$emit('update:modelValue', $event.target.value)"
                           @blur="$emit('blur', $event.target.value)" @focus="togglePopover()"/>
                    <label v-show="label">
                        {{ label }}
                        <sup v-if="required" :class="{ 'text-error': true }">*</sup>
                    </label>
                </template>
            </DatePicker>
        </div>
        <CFormText v-if="error" class="text-error">{{ error }}</CFormText>
    </div>
</template>

<script>
import {DatePicker} from 'v-calendar'

export default {
    name: 'CNInputDate',
    components: {
        DatePicker,
    },
    props: {
        disabled: {type: Boolean, default: false},
        invalid: {type: Boolean, default: false},
        label: {type: String, default: ''},
        placeholder: {type: String, default: ''},
        modelValue: String,
        variant: {type: String, default: 'border'},
        error: {type: String, default: ''},
        required: {type: Boolean, default: false},
        mode: {type: String, default: 'date'},
        start: {type: Date, default: () => null},
        end: {type: Date, default: () => null},
    },
    emits: ['update:modelValue', 'touchField', 'blur'],
    data() {
        return {
            format: {
                date: process.env.VUE_APP_DATE_FRONT_FORMAT,
                time: process.env.VUE_APP_TIME_FRONT_FORMAT,
            },
        }
    },
    watch: {
        modelValue() {
            this.$emit('touchField')
        },
    },
    mounted() {
        this.$refs.picker.$el.style.width = '100%'
    },
    methods: {
        selected(value) {
            this.$emit('update:modelValue', value)
        },
        handleFocus() {
            this.$nextTick(() => this.$refs.input.focus())
        },
    },
}
</script>

<style lang="scss">
.cn-form-input-date {
    width: 100%;
    position: relative;
    background: white;

    .vc-popover-content-wrapper {
        inset: 35px auto auto 0 !important;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input {
        width: 100%;
        outline: none;
        border: none;
        background: white;
    }

    input:focus {
        outline: none;
    }
}

.cn-form-input-date-border {
    border: 1px solid #9fafbc;
    border-radius: 8px;
    padding: 16px;
    height: 56px;

    label {
        cursor: pointer;
        display: block;
        position: absolute;
        font-size: 16px;
        line-height: 24px;
        left: 16px;
        top: 16px;
        bottom: 16px;
        color: #677a89;
        -webkit-transition: 0.2s;
        transition: 0.2s;
    }

    input {
        padding: 0;
        position: absolute;
        left: 16px;
        bottom: 4px;
        width: calc(100% - 16px);
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.002em;
        color: #1c262f;
    }

    .cn-valid + label,
    input:valid + label,
    input:focus + label {
        top: 8px;
        left: 16px;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.003em;
    }

    input::placeholder {
        color: #677a89;
    }

}

.cn-form-input-date-ghost {
    border-radius: 0;
    border-bottom: 1px solid #c3cdd5;

    label {
        cursor: pointer;
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.2px;
        color: #9aa8b5;
        -webkit-transition: 0.2s;
        transition: 0.2s;
    }

    input {
        padding: 0;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.2px;

        color: #1c262f;
        caret-color: #1c262f;
    }

    input:valid + label,
    input:focus + label {
        display: none;
    }

    input::placeholder {
        color: #677a89;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
    }
}

@import "@/styles/floating-labels";

.validation-error {
    @extend .fl_invalid;
}
</style>
