<template>
    <teleport v-if="mountedComponent" to="#header-nav">
        <CBreadcrumbItem>
            <router-link :to="{ name: 'Templates' }"> Templates</router-link>
        </CBreadcrumbItem>
        <CBreadcrumbItem active>Task Template Settings</CBreadcrumbItem>
    </teleport>
    <teleport v-if="mountedComponent" to="#templates-header">
        <span class="template-page__heading">Task Template Settings</span>
    </teleport>

    <div class="template-page">
        <div v-if="templateLoaded" class="template-page__content">
            <div @click="formDirty = true">
                <div class="template-page__form">
                    <div class="template-page__form-partial">
                        <TemplateNameInput :model-value="templateData.name" class="template-page__name"
                                           placeholder="Template name" @update:modelValue="v => templateData.name = v"
                                           @on-validation="templateNameError = $event"/>
                    </div>
                </div>
                <div class="template-page__form">
                    <div class="template-page__form-partial mt-4">
                        <OptionsDropdown :aria-disabled="!templateData.name || templateNameError"
                                         :options="templateActions" label="Task action"
                                         :model-value="templateData.type" @update:modelValue="onTaskActionChange"/>

                        <DueDateSelector :aria-disabled="!fieldsAvailable" :recurrence="templateData.recurrence"
                                         :permissions="[fieldsAvailable, 'recurrence']"
                                         :due_date_days="templateData.due_date_days"
                                         @saved="updateRecurrenceSettings"/>

                        <textarea v-model="templateData.description" :aria-disabled="!fieldsAvailable" maxlength="1000"
                                  class="template-page__description" placeholder="Description">
                        </textarea>
                    </div>
                    <div :aria-disabled="!fieldsAvailable" class="template-page__form-partial mt-4">
                        <OptionsDropdown :model-value="templateData.standard_template_id"
                                         :options="standardTemplatesOptions" label="Use CC templates"
                                         @update:modelValue="value => selectStandardTemplate(value)"/>

                        <div v-if="templateData.standard_template_id" class="template-page__file">
                            <CIcon name="cilFile"/>
                            <p class="template-page__file-link"
                               @click="toggleDocumentPreviewer('standard-templates', templateData.standard_template_id)">
                                Preview the document
                            </p>
                        </div>

                        <div v-else>
                            <FileUploadingArea :formats="fileFormats" @fileLoaded="validateSelectedFile($event)"/>
                            <div v-if="selectedFileUpload" class="template-page__file mt-3">
                                <CIcon name="cilFile"/>
                                <p>{{ selectedFileUpload.name }}</p>
                                <CIcon class="pointer ml-2" name="cilTrash" @click="removeUploadedFile"/>
                            </div>
                            <div v-else-if="templateUpload" class="template-page__file mt-3">
                                <CIcon name="cilFile"/>
                                <p class="template-page__file-link"
                                   @click="toggleDocumentPreviewer('templates', templateId)">
                                    {{ templateUpload.name }}
                                </p>
                            </div>
                        </div>

                        <OptionsDropdown :model-value="selectedStatus" :options="statuses" label="Status"
                                         @update:modelValue="v => selectedStatus = v"/>
                    </div>
                </div>
            </div>

            <div v-if="templateLoaded" class="template-page__footer">
                <CButton color="primary" variant="outline" @click="$router.push('/templates')">Cancel</CButton>
                <CButton :disabled="!fieldsAvailable" color="primary" @click="submit()">Save</CButton>
            </div>
        </div>

        <div v-if="!templateLoaded" class="template-page__spinner">
            <CSpinner color="primary"/>
        </div>

        <TemplateFormModals type="error-file" :visible="templateModal === 'error-file'" confirm-button-label="Got it"
                            @confirm="toggleTemplateModel"/>
        <TemplateFormModals type="error-format" :visible="templateModal === 'error-format'"
                            confirm-button-label="Got it" @confirm="toggleTemplateModel"/>
        <TemplateFormModals type="error-size" :visible="templateModal === 'error-size'" confirm-button-label="Got it"
                            @confirm="toggleTemplateModel"/>
        <TemplateFormModals type="error-unexpected" :visible="templateModal === 'error-unexpected'"
                            confirm-button-label="Got it" @confirm="toggleTemplateModel"/>
        <TemplateFormModals type="status-draft" :visible="templateModal === 'status-draft'"
                            confirm-button-label="Save draft" @confirm="submit(true)" @close="toggleTemplateModel"/>

        <UploadModalLoader v-if="selectedFileUpload" :file="selectedFileUpload" :visible="isUploadingFile"/>
        <FormModalLoader :visible="isSubmittingForm"/>
    </div>
    <DocumentPreviewer :visible="documentPreviewer.visible" content-type="origin" :type="documentPreviewer.type"
                       :type-id="documentPreviewer.typeId" :v2="documentPreviewer.v2"
                       @close="toggleDocumentPreviewer(false, null, null)"/>
</template>

<script>
import OnboardingScreen from '@/components/CreateTask/OnboardingScreen.vue';
import TemplateNameInput from '@/components/CreateTask/TemplateNameInput.vue';
import FileUploadingArea from '@/components/CreateTask/FileUploadingArea.vue';
import OptionsDropdown from '@/components/CreateTask/OptionsDropdown.vue';
import DueDateSelector from '@/components/CreateTask/DueDateSelector.vue';
import AddTag from '@/components/CreateTask/AddTag.vue';
import StandardTemplates from '@/api/v2/endpoints/StandardTemplates';
import Templates from "@/api/v2/endpoints/Templates";
import DocumentPreviewer from "@/components/Documents/DocumentPreviewer.vue";
import fileValidator from "@/mixin/fileValidator";
import TemplateFormModals from "@/views/Templates/Template/Components/TemplateFormModals.vue";
import UploadModalLoader from "@/components/Loaders/UploadModalLoader.vue";
import FormModalLoader from "@/components/Loaders/FormModalLoader.vue";
import fileExtension from "@/mixin/fileExtension";
import {CONTRACT_TEMPLATE_TYPE, TEMPLATE_TYPES} from "@/domain/Entities/Template/templateTypes";
import {TEMPLATE_STATUSES} from "@/domain/Entities/Template/templateStatuses";

const TASK_FILE_FORMATS = ['doc', 'docx', 'xlsx', 'xls', 'pdf', 'jpg', 'jpeg', 'gif', 'png'];
const STATUS_PUBLISHED = 'Published';
const STATUS_DRAFT = 'Draft';

export default {
    name: 'TemplateEdit',
    components: {
        FormModalLoader,
        UploadModalLoader,
        TemplateFormModals,
        AddTag,
        DueDateSelector,
        OptionsDropdown,
        FileUploadingArea,
        TemplateNameInput,
        OnboardingScreen,
        DocumentPreviewer
    },
    mixins: [fileValidator, fileExtension],
    inject: ['toast', 'downloadToast'],
    data() {
        return {
            mode: null,
            formDirty: false,
            mountedComponent: false,
            templateModal: null,
            isSubmittingForm: false,
            isUploadingFile: false,
            selectedFileUpload: null,
            templateNameError: false,
            templateLoaded: false,
            templateUpload: null,
            templateId: null,
            templateData: {
                name: null,
                type: null,
                recurrence: null,
                description: null,
                is_published: false,
                standard_template_id: null,
                due_date_days: null,
            },
            standardTemplates: [],
            statuses: TEMPLATE_STATUSES,
            selectedStatus: STATUS_DRAFT,
            documentPreviewer: {
                v2: true,
                type: null,
                typeId: null,
                visible: false,
            }
        };
    },
    computed: {
        fieldsAvailable() {
            return !!(this.templateData.name && this.templateData.type && !this.templateNameError);
        },
        fileFormats() {
            return TASK_FILE_FORMATS;
        },
        templateActions() {
            return TEMPLATE_TYPES.filter(type => type.value !== CONTRACT_TEMPLATE_TYPE);
        },
        standardTemplatesOptions() {
            return this.templateData.type
                ? this.standardTemplates
                    .filter(item => item.template_types.includes(this.templateData.type))
                    .map((item) => ({value: item.id, label: item.name}))
                : [];
        },
    },
    watch: {
        selectedStatus(value) {
            this.templateData.is_published = value === STATUS_PUBLISHED;
        },
    },
    async created() {
        this.templateId = this.$route.params.id;

        await this.loadStandardTemplates();
        this.loadTemplate();
    },
    mounted() {
        this.mountedComponent = true;
    },
    methods: {
        loadTemplate() {
            Templates
                .show(this.templateId, {
                    with_upload: 1,
                    with_task_settings: 1,
                    with_task_settings_recurrence: 1,
                })
                .then(response => {
                    this.templateLoaded = true;
                    this.templateData.name = response.data.name;
                    this.templateData.description = response.data.description;
                    this.templateData.type = response.data.task_settings.type;
                    this.templateData.due_date_days = response.data.task_settings.due_date_days;
                    this.templateData.recurrence = response.data.task_settings_recurrence;
                    this.templateData.standard_template_id = response.data.standard_template_id;
                    this.selectedStatus = response.data.is_published ? STATUS_PUBLISHED : STATUS_DRAFT;
                    this.templateUpload = response.data.upload;
                });
        },
        async loadStandardTemplates() {
            await StandardTemplates.index().then(({data}) => (this.standardTemplates = data));
        },
        validateSelectedFile(event) {
            this.selectedFileUpload = event.target.files[0];

            this
                .validateFormatAndSize(this.selectedFileUpload, this.fileFormats, 1e8)
                .then(() => {
                    if (this.isCompatibleWithTheEditor(this.selectedFileUpload.name)) {
                        this.updateTemplate();
                    }
                })
                .catch(errorType => {
                    this.toggleTemplateModel('error-' + errorType);
                    this.removeUploadedFile();
                });
        },
        submit(force = false) {
            this.toggleTemplateModel();

            if (this.templateUpload === null && this.selectedFileUpload === null && this.templateData.standard_template_id === null) {
                return this.toggleTemplateModel('error-file');
            }

            if (!force && this.selectedStatus === 'Draft') {
                return this.toggleTemplateModel('status-draft');
            }

            this.updateTemplate();
        },
        updateTemplate() {
            this.toggleIsSubmittingForm();

            Templates.update(this.templateId, this.templateData)
                .then(() => this.selectedFileUpload ? this.uploadFile() : this.handleSuccessfulUpdate())
                .catch(() => this.toggleTemplateModel('error-unexpected'))
                .finally(() => this.toggleIsSubmittingForm());
        },
        uploadFile() {
            this.toggleIsUploadingFile();

            Templates
                .upload(this.templateId, {file: this.selectedFileUpload})
                .then(() => this.handleSuccessfulUpdate())
                .finally(() => this.toggleIsUploadingFile());
        },
        handleSuccessfulUpdate() {
            this.toast('success', 'Template successfully updated!');
            this.$router.push(`/templates/${this.templateId}`);
        },
        updateRecurrenceSettings(data) {
            this.templateData.due_date_days = data.due_date_days;
            delete data.due_date_days;
            Object.keys(data).forEach(key => this.templateData.recurrence[key] = data[key])
        },
        onTaskActionChange(action) {
            this.templateData.type = action;
            this.templateData.standard_template_id = null;
        },
        toggleDocumentPreviewer(type, typeId) {
            this.documentPreviewer.type = type;
            this.documentPreviewer.typeId = typeId;
            this.documentPreviewer.visible = !this.documentPreviewer.visible;
        },
        toggleIsUploadingFile() {
            this.isUploadingFile = !this.isUploadingFile;
        },
        toggleIsSubmittingForm() {
            this.isSubmittingForm = !this.isSubmittingForm;
        },
        toggleTemplateModel(type = null) {
            this.templateModal = type;
        },
        selectStandardTemplate(id = null) {
            this.templateData.standard_template_id = id;
            this.removeUploadedFile();
        },
        removeUploadedFile() {
            this.selectedFileUpload = null;
        },
    },
};
</script>

<style lang="scss">
.template-page {
    &__heading {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.2px;
        color: #1c262f;
    }

    &__file {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 16px;
        color: #000000;

        &-link {
            cursor: pointer;
            color: #0068ad;
            text-decoration: underline;
        }

        & svg {
            flex-shrink: 0;
        }

        & p {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin: 0;

            .icon {
                color: red;
            }
        }
    }

    &__description {
        padding: 16px;
        border-radius: 8px;
        border: 1px solid #9fafbc;
        color: #677a89;
        font-size: 16px;
        height: 112px;

        &:focus {
            outline: none;
        }
    }

    &__footer {
        margin-top: 110px;
        padding-top: 24px;
        border-top: 1px solid #c3cdd5;
        display: flex;
        gap: 15px;
    }

    &__contract-info {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;

        & img {
            width: 260px;
        }

        & span {
            font-size: 16px;
            margin-top: 50px;
            margin-bottom: 20px;
            font-weight: 700;
            color: #1c262f;
        }

        & p {
            font-size: 14px;
            max-width: 400px;
            color: #677a89;
            text-align: center;
            font-weight: 400;
        }
    }

    &__form {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;

        &_request {
            & .template-page__name {
                max-width: 521px;
            }
        }

        &-partial {
            display: flex;
            flex-direction: column;
            flex: 1;
            gap: 24px;

            max-width: 521px;

            &_spaced {
                padding-top: 95px;
            }
        }
    }

    &__content {
        background: white;
        border-radius: 8px;
        padding: 40px 24px;
    }

    &__spinner {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 100%;
        min-height: 70vh;

        background: #ffffff57;
        border-radius: 8px;
    }

    &__modal {
        &-basic {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 40px 60px;
            width: 688px;
        }

        &-info {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 12px;
            text-align: center;
            margin-bottom: 24px;
        }

        &-title {
            font-size: 24px;
            font-weight: 600;
            color: #1c262f;
        }

        &-text {
            font-size: 18px;
            color: #677a89;
        }

        &-icon {
            margin-bottom: 25px;

            height: 75px !important;
            width: 75px !important;
            color: #bac6cf;

            &_info {
                height: 48px !important;
                width: 48px !important;
                color: #0068ad;
            }

            &_warning {
                height: 80px !important;
                width: 80px !important;
                color: #bac6cf;
            }
        }

        &-cancellable {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 50px;
        }

        &-actions {
            display: flex;
            gap: 16px;
        }

        &-buttons {
            display: flex;
            gap: 16px;
            margin-top: 32px;

            & button {
                min-width: 200px;
            }
        }
    }
}
</style>
